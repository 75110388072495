import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 1rem;
  padding-top: 2.5rem;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  input {
    -webkit-appearance: none;
  }
  h2 {
    font-family: "JosefinSans-Bold";
    font-weight: 700;
    text-align: center;
    color: #2f2f2f;
  }
  @media (min-width: 1024px) {
    overflow: unset;
  }
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 1000px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    form {
      width: 1050px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    h2 {
      margin-bottom: 3rem;
      text-align: left;
    }
  }
  .error {
    border-color: #e11e2c;
  }
`

export const InnerWrapper = styled.div`
  @media only screen and (min-width: 1200px) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 965px;
    margin: auto;
    padding: 0 3rem;
    h2 {
      font-size: 39px;
      line-height: 95px;
    }
    &:after {
      content: "";
      height: 100%;
      width: 1px;
      background: #707070;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const Contact = styled.div`
  @media only screen and (min-width: 1200px) {
    flex: 0 0 42%;
  }
`

export const Offer = styled.div`
  p {
    font-family: "JosefinSans-Regular";
    color: #2f2f2f;
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 42%;
    p {
      font-size: 18px;
      margin-bottom: 0.75rem;
    }
  }
`

export const DateWrapper = styled.div`
  margin: 1rem 0;
  label {
    display: block;
    font-family: "JosefinSans-Regular";
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
  }
  input {
    border: 1px solid #e2c6a9;
    border-radius: 0.25rem;
    font-family: "JosefinSans-Regular";
    padding: 0.5rem 0.25rem;
  }
  @media only screen and (min-width: 1200px) {
    input {
      padding: 0.5rem;
      padding-bottom: 8px;
      max-width: 165px;
    }
  }
`
