import React, { useState } from "react"
import { Contact, DateWrapper, InnerWrapper, Offer, Wrapper } from "./styles"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import et from "date-fns/locale/et"
import axios from "axios"
import Input from "../UI/Input"
import Submit from "../UI/Input/Submit"
import validator from "validator"
import { toast } from "react-toastify"
import { css } from "glamor"

const OfferForm = props => {
  registerLocale("et", et)
  const [inputs, setInputs] = useState({ choice: "Catering" })
  const [isFetched, setIsFetched] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleInputChange = (value, name, type) => {
    setInputs(prev => ({ ...prev, [name]: value }))
  }

  if (typeof document === "undefined") return null
  if (!validator.isEmpty(inputs?.name || "")) {
    if (document.getElementById("name") !== null) {
      document.getElementById("name").classList.remove("error")
    }
  }
  if (
    !validator.isEmpty(inputs?.email || "") &&
    validator.isEmail(inputs?.email)
  ) {
    if (document.getElementById("email") !== null) {
      document.getElementById("email").classList.remove("error")
    }
  }
  if (
    !validator.isEmail(inputs?.mobile || "") &&
    validator.isNumeric(inputs?.mobile || "")
  ) {
    if (document.getElementById("mobile") !== null) {
      document.getElementById("mobile").classList.remove("error")
    }
  }
  if (!validator.isEmpty(inputs?.location || "")) {
    if (document.getElementById("location") !== null) {
      document.getElementById("location").classList.remove("error")
    }
  }
  if (!validator.isEmpty(inputs?.people || "")) {
    if (document.getElementById("people") !== null) {
      document.getElementById("people").classList.remove("error")
    }
  }

  if (inputs?.date !== "" && inputs?.date != undefined) {
    if (document.getElementById("kuupaev") !== null) {
      document.getElementById("kuupaev").style.borderColor = "#e2c6a9"
    }
  }

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (inputs?.emailv) {
      return
    }
    setIsFetched(false)
    const formData = new FormData()
    formData.append("your-name", inputs?.name)
    formData.append("your-email", inputs?.email)
    formData.append("your-tel", inputs?.mobile)

    if (inputs?.choice === "Catering") {
      const date = inputs?.date?.toLocaleString("et", options)
      formData.append("your-choice", inputs?.choice)
      formData.append("your-location", inputs?.location || "")
      formData.append("your-date", date || "")
      formData.append("your-number", inputs?.people)
      formData.append("your-message", inputs?.wishes)
    } else if (inputs?.choice === "Nõustamine") {
      formData.append("your-choice", inputs?.choice)
      formData.append("your-location", inputs?.location || "")
      formData.append("your-message", inputs?.expectations)
    }

    const url =
      inputs?.choice === "Catering"
        ? "https://maitsvadmaitsed.ee/haldus/wp-json/contact-form-7/v1/contact-forms/186/feedback"
        : "https://maitsvadmaitsed.ee/haldus/wp-json/contact-form-7/v1/contact-forms/187/feedback"
    axios({
      url: url,
      method: "POST",
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
      .then(res => {
        setIsFetched(true)
        const { status } = res.data

        if (status !== "mail_sent") {
          const reason = res.data.invalid_fields.map(viga => {
            return viga.error_id
          })
          if (inputs?.name === undefined) {
            let el = document.getElementById("name")
            el.classList.add("error")
          }
          if (reason.includes("-ve-your-name")) {
            let el = document.getElementById("name")
            el.classList.add("error")
          }
          if (reason.includes("-ve-your-email")) {
            let el = document.getElementById("email")
            el.classList.add("error")
          }
          if (reason.includes("-ve-your-name")) {
            let el = document.getElementById("name")
            el.classList.add("error")
          }
          if (reason.includes("-ve-your-tel")) {
            let el = document.getElementById("mobile")
            el.classList.add("error")
          }
          if (
            reason.includes("-ve-your-location") ||
            inputs?.location === undefined
          ) {
            let el = document.getElementById("location")
            el.classList.add("error")
          }
          if (reason.includes("-ve-your-date") || inputs?.date === undefined) {
            let el = document.getElementById("kuupaev")
            if (el) {
              el.style.borderColor = "#e11e2c"
            }
          }
          if (
            reason.includes("-ve-your-number") ||
            inputs?.people === undefined
          ) {
            let el = document.getElementById("people")
            if (el) {
              el.classList.add("error")
            }
          }

          toast.error(
            "Kontrolli, et kõik väljad (v.a erisoovid) oleks korrektselt täidetud",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )

          setIsSuccess(false)
          setTimeout(() => {
            setIsFetched(false)
          }, 1000)
          return
        }

        toast.success("🚀 Sõnum edukalt saadetud. Aitäh!", {
          position: "top-right",
          className: css({
            background: "#18373D !important",
          }),
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })

        setInputs(null)
        setIsSuccess(true)
        setTimeout(() => {
          setIsFetched(false)
        }, 10000)
      })
      .catch(err => {
        console.log("error", err)

        setIsFetched(true)
      })
  }

  const months = [
    "Jaanuar",
    "Veebruar",
    "Märts",
    "Aprill",
    "Mai",
    "Juuni",
    "Juuli",
    "August",
    "September",
    "Oktoober",
    "November",
    "Detsember",
  ]

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <h2>Saada Päring</h2>
        <InnerWrapper>
          <Contact>
            <Input
              name="name"
              title="Nimi:"
              value={inputs?.name || ""}
              onChange={handleInputChange}
            />
            <Input
              name="email"
              title="E-mail:"
              value={inputs?.email || ""}
              onChange={handleInputChange}
            />
            <Input
              name="mobile"
              title="Telefon:"
              value={inputs?.mobile || ""}
              onChange={handleInputChange}
            />
          </Contact>
          <Offer>
            <p>Soovin</p>
            <Input
              name="choice"
              id="choice-0"
              type="radio"
              title="Catering"
              value="Catering"
              defaultChecked
              onChange={handleInputChange}
            />
            <Input
              name="choice"
              id="choice-1"
              type="radio"
              title="Nõustamine"
              value="Nõustamine"
              onChange={handleInputChange}
            />
            {inputs?.choice === "Nõustamine" ? (
              <>
                <Input
                  name="location"
                  title="Asukoht"
                  value={inputs?.location}
                  onChange={handleInputChange}
                />
                <Input
                  name="expectations"
                  title="Ootused nõustamisele"
                  type="textarea"
                  value={inputs?.expectations}
                  onChange={handleInputChange}
                />
              </>
            ) : (
              <>
                <Input
                  name="location"
                  title="Asukoht"
                  value={inputs?.location}
                  onChange={handleInputChange}
                />
                <DateWrapper>
                  <label htmlFor="kuupaev">Toimumise kuupäev</label>
                  <DatePicker
                    locale="et"
                    id="kuupaev"
                    selected={inputs?.date}
                    autoComplete="off"
                    onChange={val => {
                      handleInputChange(val, "date")
                    }}
                  />
                </DateWrapper>
                <Input
                  size="small"
                  name="people"
                  title="Inimeste arv"
                  type="number"
                  value={inputs?.people}
                  onChange={handleInputChange}
                />
                <Input
                  name="wishes"
                  title="Erisoovid"
                  type="textarea"
                  value={inputs?.wishes}
                  onChange={handleInputChange}
                />
              </>
            )}
          </Offer>
          <Input
            type="text"
            value={inputs?.emailv}
            name="emailv"
            vale
            onChange={handleInputChange}
          />
        </InnerWrapper>
        <Submit title="Saada" />
      </form>
    </Wrapper>
  )
}

export default OfferForm
