import React from "react"
import { Wrapper } from "./styles"

const Button = props => {
  const { title, color, size, centered } = props || {}

  return (
    <Wrapper
      color={color}
      size={size}
      centered={centered}
      onClick={props.onClick}
    >
      {title}
    </Wrapper>
  )
}

export default Button
