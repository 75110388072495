import React from "react"
import { Close, MobileClose, Wrapper } from "./styles"

const Modal = React.forwardRef((props, ref) => {
  if (typeof document === "undefined") return null
  return (
    <Wrapper open={props.isShowing} ref={ref} background={props.background}>
      <Close onClick={props.toggle}>
        <span></span>
        <span></span>
      </Close>
      <MobileClose onClick={props.toggle} />
      {props.isShowing && props.children}
    </Wrapper>
  )
})

export default Modal
