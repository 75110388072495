import React from "react"
import { SubmitWrapper } from "./styles"

const Submit = props => {
  return (
    <SubmitWrapper>
      <input type="submit" value={props.title} />
    </SubmitWrapper>
  )
}

export default Submit
