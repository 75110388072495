import styled, { css } from "styled-components"
import mobileArrow from "../../../assets/svg/mobilearrow.svg"

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: -100vh;
  left: 0;
  z-index: 100;
  pointer-events: none;
  transition: 0.25s ease;
  ${props =>
    props.background === "light" &&
    css`
      background-color: #fff;
      opacity: 1;
    `}
  ${props =>
    props.open &&
    css`
      top: 0;
      pointer-events: all;
    `}
`

export const MaxHeight = styled.div`
  height: 100%;
`
export const Close = styled.div`
  cursor: pointer;
  display: none;
  height: 25px;
  width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  span {
    display: block;
    top: 48%;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #707070;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(135deg);
    }
  }
  @media only screen and (min-width: 1200px) {
    display: block;

    right: 50px;
    top: 50px;
  }
`

export const MobileClose = styled.div`
  position: absolute;
  left: 2rem;
  top: 2.8rem;
  width: 23px;
  height: 17px;
  background-image: url(${mobileArrow});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
  @media (min-width: 1200px) {
    display: none;
  }
`
