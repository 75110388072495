import React from "react"
import { InputWrapper } from "./styles"

const Input = props => {
  const { title, size, type, error, name, value, id, defaultChecked, vale } =
    props || {}

  return (
    <InputWrapper error={error} size={size} inputType={type} vale={vale}>
      <label htmlFor={id || name}>{title}</label>
      {type === "textarea" ? (
        <textarea
          rows="5"
          name={name}
          id={name}
          value={value || ""}
          onChange={e => props.onChange(e.target.value, name)}
        ></textarea>
      ) : (
        <input
          name={name}
          id={id || name}
          type={type || "text"}
          min="1"
          value={value || ""}
          defaultChecked={defaultChecked || false}
          onChange={e => {
            props.onChange(e.target.value, name, e.target.type)
          }}
        />
      )}
    </InputWrapper>
  )
}

export default Input
