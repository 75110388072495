import { func } from "prop-types"
import React, { useState } from "react"

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)
  const reload = () => window.location.reload()

  function toggle() {
    setIsShowing(!isShowing)
  }

  return { setIsShowing, isShowing, toggle, reload }
}

export default useModal
