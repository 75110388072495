import styled, { css } from "styled-components"

export const InputWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  label {
    color: #2f2f2f;
    font-family: "JosefinSans-Regular";
    display: block;
    padding-left: 0.5rem;
    padding-bottom: 0.25rem;
  }
  input,
  textarea {
    border: 1px solid #e2c6a9;
    font-family: "JosefinSans-Regular";
    border-radius: 2px;
    padding: 0.5rem 0.25rem;
  }
  @media only screen and (min-width: 1200px) {
    margin: 1.25rem 0;
    label {
      font-size: 18px;
    }
    input,
    textarea {
      width: 100%;
      padding: 0.5rem;
      padding-bottom: 8px;
    }
  }
  ${props =>
    props.inputType === "radio" &&
    css`
      display: flex;
      align-items: center;
      flex-direction: row;
      input {
        position: relative;
        cursor: pointer;
        order: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid #e2c6a9;
        border-radius: 50%;
        padding: 8px;
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background: #fff;
          opacity: 0;
        }
        &:checked {
          background: #e2c6a9;
          &:after {
            opacity: 1;
          }
        }
      }
      label {
        cursor: pointer;
        order: 2;
        padding-bottom: 0;
      }
      @media only screen and (min-width: 1200px) {
        margin: 0;
        input {
          width: auto;
        }
      }
    `}
  ${props =>
    props.size === "small" &&
    css`
      input {
        max-width: 165px;
      }
    `}
    ${props =>
    props.vale &&
    css`
      position: absolute;
      bottom: 0;
      opacity: 0;
      pointer-events: none;
    `}
`

export const SubmitWrapper = styled.div`
  input {
    background-color: #e2c6a9;
    height: 45px;
    width: 150px;
    color: #fff;
    font-family: "JosefinSans-Regular";
    font-weight: 400;
    border: unset;
  }
  @media only screen and (min-width: 1200px) {
    width: 860px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    input {
      font-size: 24px;
    }
  }
`
