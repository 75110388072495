import styled, { css } from "styled-components"

export const Wrapper = styled.button`
  border: unset;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: "JosefinSans-Regular";
  margin: 1rem 0;
  font-size: 24px;
  transition: 0.25s ease;
  @media only screen and (min-width: 1200px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  ${props =>
    props.size === "small" &&
    css`
      width: 150px;
      height: 45px;
    `}
  ${props => props.size === "medium" && css``}
  ${props =>
    props.size === "large" &&
    css`
      width: 290px;
      height: 61px;
    `}
  ${props =>
    props.color === "light" &&
    css`
      background-color: #e2c6a9;
      color: #fff;
      &:hover {
        background-color: #c8ad91;
      }
    `}
    ${props =>
    props.centered &&
    css`
      display: block;
      margin: auto;
    `}
`
